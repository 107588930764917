/* Hero Section Styles */
.hero {
    height: 80vh;
    background: url("https://source.unsplash.com/1600x900/?apartment,rent") no-repeat center center/cover;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: black;
  }
  
  .hero-content {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .hero-button {
    background-color: #E74C3C;
    padding: 15px 30px;
    font-size: 18px;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  /* Media Section Styles */
  .media-section {
    width: 100%;
    padding: 20px;
    background-color: rgba(255, 255, 255, 0.8); /* Light background for contrast */
    display: flex;
    flex-direction: column;
    align-items: center; /* Center content horizontally */
  }
  
  .media-photos, .media-videos {
    text-align: center;
    margin-bottom: 40px;
    width: 30%;
  }
  
  
  
  /* Image Styling */
  .media-image {
    width: 100%;
    max-width: 60px; /* Adjust as needed */
    height: auto;
    border-radius: 10px;
    justify-content: center;
  }
  
  /* Video Styling */
  .video-frame {
    width: 50%;
    max-width: 150px; /* Adjust as needed */
    height: auto;
    border-radius: 10px;
  }
  
  /* Responsive Design for smaller screens */
  @media (max-width: 768px) {
    .media-image, .video-frame {
      max-width: 40%; /* Ensure media scales down on smaller screens */
    }
  }
  