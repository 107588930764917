body {
  margin: 0;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  color: #333;
  background-color: #f4f4f4;
}

/* General */
h1, h2, h3 {
  color: #2C3E50;
}

a {
  text-decoration: none;
  color: inherit;
}

button {
  cursor: pointer;
}
