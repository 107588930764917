/* Features Section Styles */
.features-section {
    padding: 50px;
    background-color: #fff;
    text-align: center;
  }
  
  .features-list {
    display: flex;
    justify-content: space-around;
    margin-top: 30px;
  }
  
  .feature {
    width: 30%;
    text-align: center;
  }
  
  .feature h3 {
    color: #E74C3C;
  }
  